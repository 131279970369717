import styled from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 16px;
  max-width: 430px;

  ${atMinWidth.sm`
    gap: 24px;
  `}
`;

export const Stars = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;

  p{
    ${font('text', 'xs', '400')}
    color: ${props => (props.isDark ? colors.base.white : colors.gray[600])};
    color: ${props => props.variant === 'Form-V2' && colors.gray[400]};
  }

  img {
    max-width: 100px;
  }

  ${atMinWidth.sm`
    gap: 20px;

    p{
      ${font('text', 'sm', '400')}
      color: ${props => props.variant === 'V2' && colors.gray[600]};
    }

    img {
      max-width: 130px;
    }
  `}
`;

export const Logos = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  ${atMinWidth.md`
    gap: 32px;
  `}

  img {
    filter: ${props =>
      props.isDark &&
      'brightness(0) saturate(100%) invert(73%) sepia(10%) saturate(351%) hue-rotate(193deg) brightness(92%) contrast(86%)'};
    max-width: 23%;
  }
`;
